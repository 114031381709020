<template>
    <div>
        <main class="container-fluid">
            <div class="row mx-md-4">
				<!-- Breadcrumb -->
				<div class="col-md-12 mb-4">
					<div class="card shadow border-0 round">
						<div class="card-body px-2 py-1">
							<nav aria-label="breadcrumb">
								<ol class="breadcrumb bg-transparent mb-0">
									<li class="breadcrumb-item text-info"><router-link to="/" class=" text-decoration-none">Home</router-link></li>
									<li class="breadcrumb-item text-secondary">
										<router-link to="/member/profile" class=" text-decoration-none">Member</router-link>
									</li>
									<li class="breadcrumb-item text-secondary">
										<router-link to="/member/shopping-cart" class=" text-decoration-none">Keranjang</router-link>
									</li>
									<li class="breadcrumb-item active" aria-current="page">Checkout</li>
								</ol>
							</nav>
						</div>
					</div>
				</div>
				
				<div class="col-md-12 mb-4">
					<div class="card shadow border-primary round">
						<div class="card-body p-4">
							<h4 class="text-center my-2"><i class="fa fa-cart-plus mr-3"></i>Checkout Keranjang </h4>
							<hr />
							<div class="px-3 py-2">
								<div class="row">
									<div class="col-md-6">
										<div class="card shadow border-success round mb-3">
											<div class="card-body px-3 py-4 max-content-user customScroolBar" style="max-height: 175vh;">
												<h5 class="mb-4 text-success text-center"><strong>Detail Keranjang</strong></h5>
												<div>
													<table class="table mb-0">
														<thead>
															<tr class="bg-light">
																<th></th>
																<th></th>
																<th>Produk</th>
																<th>Total</th>
															</tr>
														</thead>
														<tbody v-for="(product, index) in data_checkout.product" :key="product.prod_id">
															<tr>
																<td class="text-center text-muted align-middle">{{ ++index }}</td>
																<td style="width: 100px;" class="align-middle"><img v-lazy="productImage(product.prod_image)" @error="imageLoadError" class="card-img-top w-100" :alt="product.prod_image" /></td>
																<td class="align-middle mx-2">{{ product.prod_name | str_limit(28) }} x {{ product.keranjang_prod_quantity }}<br /><span class="text-muted">{{ product.prod_weight }} gr</span></td>

																<td class="align-middle mx-2" v-if="product.kupon_effect">{{ (product.raw_price * product.keranjang_prod_quantity) | formatCurrency('IDR') }}</td>
																<td class="align-middle mx-2" v-else>{{ product.raw_ammount | formatCurrency('IDR') }}</td>
															</tr>
														</tbody>
														<tr>
															<td></td>
															<td style="width: 100px;"></td>
															<td class="h6">Harga Total</td>
															<td class="h6">{{ total_hargaProduct(data_checkout.product) | formatCurrency('IDR') }}</td>
														</tr>
														<tr v-if="is_kuponUsed && kupon_used != ''">
															<td></td>
															<td style="width: 100px;"></td>
															<td>Diskon kupon<br/><small class="text-muted">({{ kupon_used }})</small></td>
															<td class="text-danger">{{ total_diskon(data_checkout.product) | formatCurrency('IDR') }}</td>
														</tr>
														<tr v-if="is_kuponUsed && kupon_used != ''">
															<td></td>
															<td style="width: 100px;"></td>
															<td class="h6">Harga Setelah Diskon</td>
															<td class="h6">{{ total_hargaProduct(data_checkout.product) - total_diskon(data_checkout.product) | formatCurrency('IDR') }}</td>
														</tr>
														<tr>
															<td></td>
															<td style="width: 100px;"></td>
															<td>PPN</td>
															<td>{{ data_checkout.ppn | formatCurrency('IDR') }}</td>
														</tr>
														<tr>
															<td></td>
															<td style="width: 100px;"></td>
															<td class="h6">Total Belanja</td>
															<td class="h6">{{ data_checkout.grand_total | formatCurrency('IDR') }}</td>
														</tr>
														<tr>
															<td></td>
															<td style="width: 100px;"></td>
															<td>Biaya Kirim</td>
															<td>{{ ongkir | formatCurrency('IDR') }}</td>
														</tr>
														<tr class="bg-light" id="total_pembayaran">
															<td></td>
															<td style="width: 100px;"></td>
															<td class="h6"><strong>Total Pembayaran</strong></td>
															<td class="h5 text-success"><strong>{{ parseInt(data_checkout.grand_total) + parseInt(ongkir)  | formatCurrency('IDR') }}</strong></td>
														</tr>
													</table>
												</div>
											</div>
										</div>
									</div>
									<div class="col-md-6">
										<div class="card shadow border-info round mb-3">
											<div class="card-body py-3 px-4">
												<h5 class="text-info text-center mb-1 mt-2">Detail Pengiriman <i class="fa fa-truck ml-2"></i></h5>
												<hr class="w-50" />
												<h6 class="ml-4"><i class="fa fa-map-marked-alt"></i> Tujuan Pengiriman:</h6>
												<div class="card border-secondary round mb-3">
													<div class="card-body py-3 px-4">
														<div class="text-center my-3" v-if="is_refreshAddress">
															<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
														</div>
														<div v-else>
															<div v-if="address.length > 0" class="d-flex justify-content-between align-items-center text-muted">
																<div class="w-100 d-flex align-items-center justify-content-between" v-if="selected_address">
																	<div>
																		<h5 class="mb-1 text-dark">{{ selected_address.mem_add_name | capitalize }}</h5>
																		<tr>
																			<td>Penerima</td>
																			<td><span class="mx-2">:</span></td>
																			<td>{{ selected_address.mem_add_recipient_name | capitalize }} ({{ selected_address.mem_add_phone }})</td>
																		</tr>
																		<tr>
																			<td>Alamat</td>
																			<td><span class="mx-2">:</span></td>
																			<td>{{ selected_address.mem_add_propinsi }} | {{ selected_address.mem_add_city }} | {{ selected_address.mem_add_kecamatan }} | {{ selected_address.mem_add_address }} | {{ selected_address.mem_add_zipcode }}</td>
																		</tr>
																	</div>
																	<button class="btn btn-secondary" type="button" id="dropdown_setAlamat" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="fa fa-map-marker-alt"></i><br>Ubah</button>
																	<div class="dropdown-menu" aria-labelledby="dropdown_setAlamat">
																		<button @click="set_selectedAddress(addr)" class="dropdown-item" v-for="addr in address" :key="addr.mem_add_id">{{ addr.mem_add_name }}</button>
																		<hr class="my-2">
																		<router-link to="/member/profile#member-address" class="dropdown-item">
																			<i class="fa fa-plus-circle"></i> Tambah Alamat
																		</router-link>
																	</div>
																</div>
															</div>
															<div class="text-center" v-else>
																<form ref="formAddAddress" class="px-3 py-2 text-left" @submit.prevent="addAddress">
																	<label class="text-muted mb-1">Label Alamat</label>
																	<div class="input-group mb-2 mr-sm-2">
																		<div class="input-group-prepend">
																			<div class="input-group-text bg-white"><i class="far fa-address-card"></i></div>
																		</div>
																		<input type="text" class="form-control pr-5" placeholder="Rumah, Kantor, Apartemen, Dropship" name="address_name" required>
																	</div>
																	<label class="text-muted mb-1">Penerima</label>
																	<div class="input-group mb-2 mr-sm-2">
																		<div class="input-group-prepend">
																			<div class="input-group-text bg-white"><i class="fa fa-user"></i></div>
																		</div>
																		<input type="text" class="form-control pr-5" placeholder="Nama Penerima" name="recipient_name" required>
																	</div>
																	<label class="text-muted mb-1">Telepon Penerima</label>
																	<div class="input-group mb-2 mr-sm-2">
																		<div class="input-group-prepend">
																			<div class="input-group-text bg-white"><i class="fa fa-phone-alt"></i></div>
																		</div>
																		<input type="number" class="form-control pr-5" placeholder="Misal: 081310005877" name="telp" required>
																	</div>
																	<label class="text-muted mb-1">Alamat</label>
																	<div class="input-group mb-2 mr-sm-2">
																		<div class="input-group-prepend">
																			<div class="input-group-text bg-white"><i class="fa fa-map-marker-alt"></i></div>
																		</div>
																		<textarea class="form-control pr-5" placeholder="Nama Jalan, No. Rumah, No. Kompleks atau Nama Gedung, Lantai atau No. Unit, RT/RW" name="address" required></textarea>
																	</div>
																	<label class="text-muted mb-1">Provinsi</label>
																	<div class="input-group mb-2 mr-sm-2">
																		<div class="input-group-prepend">
																			<div class="input-group-text bg-white"><i class="fa fa-city"></i></div>
																		</div>
																		<select class="custom-select" name="province" @change="on_changeProvince($event)" required>
																			<option selected disabled>Pilih Provinsi</option>
																			<option :value="prov.propinsi_id" v-for="prov in province" :key="prov.propinsi_id">{{ prov.propinsi_nama }}</option>
																		</select>
																	</div>
																	<label class="text-muted mb-1">Kota</label>
																	<div class="input-group mb-2 mr-sm-2">
																		<div class="input-group-prepend">
																			<div class="input-group-text bg-white"><i class="fa fa-city"></i></div>
																		</div>
																		<select class="custom-select" name="city" @change="on_changeCity($event)" required>
																			<option selected disabled v-if="is_loadCity">Tunggu...</option>
																			<option selected disabled v-else>Pilih Kota</option>
																			<option :value="kab.kab_id" v-for="kab in city" :key="kab.propinsi_id">{{ kab.kab_nama }}</option>
																		</select>
																	</div>
																	<label class="text-muted mb-1">Kecamatan</label>
																	<div class="input-group mb-2 mr-sm-2">
																		<div class="input-group-prepend">
																			<div class="input-group-text bg-white"><i class="fa fa-city"></i></div>
																		</div>
																		<select class="custom-select" name="kecamatan" @change="on_changeKec($event)"  required>
																			<option selected disabled v-if="is_loadKec">Tunggu...</option>
																			<option selected disabled v-else>Pilih Kecamatan</option>
																			<option :value="kec.kec_id" v-for="kec in kecamatan" :key="kec.kec_id">{{ kec.kec_name }}</option>
																		</select>
																	</div>
																	<label class="text-muted mb-1">Kode Pos</label>
																	<div class="input-group mb-2 mr-sm-2">
																		<div class="input-group-prepend">
																			<div class="input-group-text bg-white"><i class="fa fa-mail-bulk"></i></div>
																		</div>
																		<input type="number" class="form-control pr-5" placeholder="Kode Pos" name="zipcode" required>
																	</div>
																	<div class="w-100 mt-4 text-center">
																		<button type="submit" class="btn btn-primary w-75">Simpan &nbsp; <i class="fa fa-paper-plane"></i></button>
																	</div>
																</form>
															</div>
														</div>
													</div>
												</div>
												<div class="mx-3" v-if="account_type != 'personal'">
													<label class="mb-1 text-muted">Menggunakan NPWP?</label>
													<div class="mb-2">
														<div class="form-check form-check-inline">
															<input class="form-check-input mr-2" type="radio" v-model="npwp" id="inlineRadio1" value="true">
															<label class="form-check-label" for="inlineRadio1">Ya, gunakan</label>
														</div>
														<div class="form-check form-check-inline">
															<input class="form-check-input ml-2" type="radio" v-model="npwp" id="inlineRadio2" value="false">
															<label class="form-check-label" for="inlineRadio2">Tidak</label>
														</div>
													</div>
												</div>
												<div class="mx-3">
													<label class="mb-1 text-muted">Kurir Pengiriman</label>
													<div class="input-group mb-2 mr-sm-2">
														<div class="input-group-prepend">
															<div class="input-group-text bg-white"><i class="fa fa-truck"></i></div>
														</div>
														<select class="custom-select" v-model="courier_selected" @change="on_changeCourier($event)" required>
															<option selected disabled>Pilih Kurir</option>
															<template v-for="kurir in courier">
																<option :value="kurir.kurir_key" :key="kurir.kurir_id" v-if="kurir.kurir_key == 'free_delivery'" class="text-warning h6">{{ kurir.kurir_name }}</option>
																<option :value="kurir.kurir_key" :key="kurir.kurir_id" v-else>{{ kurir.kurir_name }}</option>
															</template>
														</select>
													</div>
												</div>
												<div class="mx-3 mb-2">
													<label class="mb-1 text-muted">Tipe Pengiriman</label>
													<div class="input-group mr-sm-2">
														<div class="input-group-prepend">
															<div class="input-group-text bg-white"><i class="fa fa-truck"></i></div>
														</div>
														<select class="custom-select" v-model="carrier_selected" @change="on_changeCourierType($event)"  required>
															<option selected disabled v-if="is_loadCourierType">Tunggu...</option>
															<option selected disabled v-else>Pilih Kurir</option>
															<template v-for="tipe in courier_type">
																<option :value="tipe.carrier+'~'+tipe.carrier_code+'~'+tipe.carrier_etd+'~'+tipe.carrier_name+'~'+tipe.carrier_price+'~'+tipe.carrier_service" :key="tipe.kurir_id" v-if="tipe.carrier_price == 0" class="text-warning h6">{{ tipe.carrier }}</option>
																<option :value="tipe.carrier+'~'+tipe.carrier_code+'~'+tipe.carrier_etd+'~'+tipe.carrier_name+'~'+tipe.carrier_price+'~'+tipe.carrier_service" :key="tipe.kurir_id" v-else>{{ tipe.carrier }}</option>
															</template>
														</select>
													</div>
													<small class="text-info ml-5 d-none" id="estimasi_sampai"></small>
												</div>
												<div class="mx-3">
													<label class="mb-1 text-muted">Catatan</label>
													<div class="input-group mb-2 mr-sm-2">
														<div class="input-group-prepend">
															<div class="input-group-text bg-white"><i class="fa fa-pen"></i></div>
														</div>
														<textarea class="form-control" placeholder="Catatan untuk duniamurah.com" v-model="trans_note"></textarea>
													</div>
												</div>
												<hr class="w-50 mt-4" />
												<h5 class="text-info text-center mb-3 mt-2">Pembayaran <i class="fa fa-cash-register ml-2"></i></h5>
												<div class="card border-primary round mb-3">
													<div class="card-body py-3 px-4">
														<tr class="h6 d-flex align-items-center">
															<td style="width: 85px;" class="align-middle m-3 shadow round"><img src="@/assets/images/bank-bca.png" @error="imageLoadError" class="card-img-top w-100" alt="bank BCA" /></td>
															<td>BCA KCP Kelapa Gading<br/>7480639477<br/>a.n. PT. Putra Perdana Sentosa</td>
														</tr>
														<tr class="h6 d-flex align-items-center">
															<td style="width: 85px;" class="align-middle m-3 shadow round"><img src="@/assets/images/bank-bni.png" @error="imageLoadError" class="card-img-top w-100" alt="bank BNI" /></td>
															<td>BNI<br/>7480639470<br/>a.n. Putra Perdana Sentosa</td>
														</tr>
													</div>
													<div class="mx-5 mb-4">
														<button class="btn btn-success w-100" @click="setujuiPembayaran()" v-if="carrier != '' && carrier_code != '' && carrier_price != '' && carrier_name != '' && carrier_pc != '' && carrier_etd != ''">SETUJUI PEMBAYARAN</button>
														<button class="btn btn-success w-100 disabled" style="background-color: #28a745; color: #fff;" disabled v-else>SETUJUI PEMBAYARAN</button>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
            </div>
        </main>
    </div>
</template>

<script>
export default {
  	data () {
 		return {
			data_checkout: [],
			npwp: false,
			jumlah_produk_keranjang: [],
			province: [],
			city: [],
			add_city: '',
			is_loadCity: false,
			add_kecamatan: '',
			kecamatan: [],
			is_loadKec: false,
			address: [],
			is_refreshAddress: false,
			selected_address: '',
			selected_idAddress: '',
			selected_subdistrict: '',
			courier: [],
			courier_selected: '',
			courier_type: [],
			carrier_selected: '',
			is_loadCourierType: false,
			address: [],
			is_kuponUsed: false,
			ongkir: 0,
			kupon_used: '',
			trans_note: '',
			carrier: '',
			carrier_code: '',
			carrier_price: '',
			carrier_name: '',
			carrier_pc: '',
			carrier_etd: '',
 		}
	},
	computed: {
		isCheckout() {
			return this.$store.getters.isCheckout
		},
		isLoading() {
			return this.$store.getters.isLoading
		},
      	account_type(){
			if(this.$store.getters.account_type) {
				return this.$store.getters.account_type
			}
			return localStorage.getItem('account_type')
      	},
	},
	methods: {
		getProvince() {
			window.axios.get('/province')
       		.then(response => {
				this.province = response.data.data
				this.add_city = 'Pilih Kota'
				this.add_kecamatan = 'Pilih Kecamatan'
       		})
       		.catch(error => {
       			console.log(error)
			});
		},
		on_changeProvince(event) {
			this.is_loadCity = true
			window.axios.get('/city?province_id='+ event.target.value)
       		.then(response => {
				this.selected_subdistrict = ''
				this.city = response.data.data
				this.is_loadCity = false
				this.add_city = 'Pilih Kota'
				this.add_kecamatan = 'Pilih Kecamatan'
				this.courier_selected = 'Pilih Kurir'
				this.courier_type = []
				this.carrier_selected = 'Pilih Kurir'
				this.ongkir = 0
				this.carrier= ''
				this.carrier_code= ''
				this.carrier_price= ''
				this.carrier_name= ''
				this.carrier_pc= ''
				this.carrier_etd= ''
				$('#estimasi_sampai').empty()
				$('#estimasi_sampai').addClass('d-none')
       		})
       		.catch(error => {
       			console.log(error)
			});
		},
		on_changeCity(event) {
			this.is_loadKec = true
			window.axios.get('/subdistrict?city_id='+ event.target.value)
       		.then(response => {
				this.selected_subdistrict = ''
				this.kecamatan = response.data.data
				this.is_loadKec = false
				this.add_kecamatan = 'Pilih Kecamatan'
				this.courier_selected = 'Pilih Kurir'
				this.courier_type = []
				this.carrier_selected = 'Pilih Kurir'
				this.ongkir = 0
				this.carrier= ''
				this.carrier_code= ''
				this.carrier_price= ''
				this.carrier_name= ''
				this.carrier_pc= ''
				this.carrier_etd= ''
				$('#estimasi_sampai').empty()
				$('#estimasi_sampai').addClass('d-none')
       		})
       		.catch(error => {
       			console.log(error)
			});
		},
		on_changeKec(event) {
			this.selected_subdistrict = event.target.value
			this.courier_selected = 'Pilih Kurir'
			this.courier_type = []
			this.carrier_selected = 'Pilih Kurir'
			this.ongkir = 0
			this.carrier= ''
			this.carrier_code= ''
			this.carrier_price= ''
			this.carrier_name= ''
			this.carrier_pc= ''
			this.carrier_etd= ''
			$('#estimasi_sampai').empty()
			$('#estimasi_sampai').addClass('d-none')
		},
		getAddress() {
			this.is_refreshAddress = true
			window.axios.get('/me/profile/address', {
       		        headers: {
       		            "token": this.$store.getters.token_member
       		        }
       		    }
       		)
       		.then(response => {
				this.address = response.data.data
				if(this.address != '') {
					this.selected_address = this.address[0]
					this.selected_idAddress = this.selected_address.mem_add_id
					this.selected_subdistrict = this.selected_address.kec_id
				}
				this.getCourier(this.selected_idAddress)
				this.is_refreshAddress = false
       		})
       		.catch(error => {
       			console.log(error)
			});
		},
		set_selectedAddress(address) {
			this.selected_address = address
			this.selected_idAddress = this.selected_address.mem_add_id
			this.selected_subdistrict = this.selected_address.kec_id
			this.courier_selected = 'Pilih Kurir'
			this.courier_type = []
			this.carrier_selected = 'Pilih Kurir'
			this.ongkir = 0
			this.carrier= ''
			this.carrier_code= ''
			this.carrier_price= ''
			this.carrier_name= ''
			this.carrier_pc= ''
			this.carrier_etd= ''
			this.getCourier(this.selected_idAddress)
			$('#estimasi_sampai').empty()
			$('#estimasi_sampai').addClass('d-none')
		},
		addAddress() {
			let formAddAddress = this.$refs.formAddAddress
			let formData = new FormData(formAddAddress)

			window.axios.post('/me/profile/address', formData, {
       		        headers: {
						"Content-Type": "application/x-www-form-urlencoded",
       		            "token": this.$store.getters.token_member
       		        }
       		    }
       		)
       		.then(response => {
				$('html, body').stop().animate({
					scrollTop: 83
				}, 1000, 'easeInOutExpo')
				this.getAddress()
       		})
       		.catch(error => {
       			console.log(error)
			});
		},
		getCourier(province) {
			window.axios.get('/courrier?address_id='+ province)
       		.then(response => {
				this.courier = response.data.data
				this.courier_selected = 'Pilih Kurir'
				this.carrier_selected = 'Pilih Kurir'
       		})
       		.catch(error => {
       			console.log(error)
			});
		},
		on_changeCourier(event) {
			if(this.selected_subdistrict != '') {
				$('#estimasi_sampai').empty()
				$('#estimasi_sampai').addClass('d-none')
				this.ongkir = 0
				this.carrier= ''
				this.carrier_code= ''
				this.carrier_price= ''
				this.carrier_name= ''
				this.carrier_pc= ''
				this.carrier_etd= ''
				this.carrier_selected = 'Tunggu...'
				this.is_loadCourierType = true
				const weight = this.data_checkout.total_weight
				window.axios.get('/ongkir?subdistrict_id='+ this.selected_subdistrict +'&weight='+ weight +'&courier='+ event.target.value)
				.then(response => {
					if(Array.isArray(response.data.data)) {
						this.courier_type = response.data.data
					}
					else {
						this.courier_type = [response.data.data]
					}
					this.is_loadCourierType = false
					this.carrier_selected = 'Pilih Kurir'
				})
				.catch(error => {
					console.log(error)
				});
			}
		},
		on_changeCourierType(event) {
			let [carrier, carrier_code, carrier_etd, carrier_name, carrier_price, carrier_service] = event.target.value.split('~')
			if(isNaN(carrier_price)) {
				this.ongkir = 0
				this.carrier= ''
				this.carrier_code= ''
				this.carrier_price= ''
				this.carrier_name= ''
				this.carrier_pc= ''
				this.carrier_etd= ''
			}
			else {
				this.ongkir = carrier_price
				this.carrier= carrier
				this.carrier_code= carrier_code
				this.carrier_price= carrier_price
				this.carrier_name= carrier_name
				this.carrier_pc= carrier_service
				if(carrier_etd != '' && !carrier_etd) {
					this.carrier_etd= carrier_etd
					$('#estimasi_sampai').html('Perkiraan waktu sampai tujuan: '+ this.carrier_etd +' hari')
				}
				else {
					this.carrier_etd= 'Tidak diketahui'
					$('#estimasi_sampai').html('Perkiraan waktu sampai tujuan: '+ this.carrier_etd)
				}
				$('#estimasi_sampai').removeClass('d-none')
				$('#total_pembayaran').addClass('animate__animated animate__flash');
				setTimeout(() => {
					$('#total_pembayaran').removeClass('animate__animated animate__flash');
				}, 2000);
			}
		},
		total_hargaProduct(data) {
			let hargaTotal = 0
			let len = data.length

			for(let i=0; i<len; i++) {
				if(data[i].kupon_effect && parseInt(data[i].kupon_effect) > 0) {
					this.is_kuponUsed = true
					hargaTotal +=  (data[i].raw_price * data[i].keranjang_prod_quantity)
				}
				else {
					hargaTotal += data[i].raw_ammount
				}
			}
			return hargaTotal
		},
		total_diskon(data) {
			let totalDiskon = 0
			let len = data.length

			for(let i=0; i<len; i++) {
				if(data[i].kupon_effect && parseInt(data[i].kupon_effect) > 0) {
					totalDiskon += (data[i].raw_price * data[i].keranjang_prod_quantity) * (parseInt(data[i].kupon_effect) / 100)
				}
			}
			
			return Math.round(totalDiskon)
		},
		setujuiPembayaran() {
			if(this.carrier != '' && this.carrier_code != '' && this.carrier_price != '' && this.carrier_name != '' && this.carrier_pc != '' && this.carrier_etd != '') {
				if(this.address != '' || this.selected_address != '') {
					let formData = new FormData()
					formData.append('kupon_code', this.kupon_used)
					formData.append('address_id', this.selected_address.mem_add_id)
					formData.append('trans_note', this.trans_note)
					formData.append('carrier', this.carrier)
					formData.append('carrier_code', this.carrier_code)
					formData.append('carrier_price', this.carrier_price)
					formData.append('carrier_name', this.carrier_name)
					formData.append('carrier_pc', this.carrier_pc)
					formData.append('carrier_etd', this.carrier_etd)
					formData.append('npwp', this.npwp)

					window.axios.post('/transaction', formData, {
							headers: {
								"Content-Type": "application/x-www-form-urlencoded",
								"token": this.$store.getters.token_member
							}
						}
					)
					.then((response) => {
						if(response.data.success) {
							window.NavbarDirtyAccess.get_productKeranjang()
							this.$swal({
								title: 'Transaksi Berhasil!', 
								html:	`
											<h5 class="text-success">`+ response.data.data.invoice_code +`</h5>
											<p class="mb-0">Silahkan lakukan pembayaran dan tunggu konfirmasi oleh duniamurah.com</p>
										`,
								icon: 'success',
								showConfirmButton: true,
							});
							this.$router.push('/member/purchase')
						}
						else {
							this.$swal({
								title: 'Oops!', 
								html: '<p>Terjadi kesalahan</p>',
								icon: 'error',
								timer: 4000,
								showConfirmButton: false,
							});
							this.$router.push('/member/shopping-cart')
						}
					})
					.catch((error) => {
						console.log(error)
					})
				}
			}
		}
	},
    created () {
		$(document).scrollTop(0)
		if(this.isCheckout) {
			this.data_checkout = this.$store.getters.data_checkout
			this.kupon_used = this.$store.getters.kupon_used
			
			
			this.getProvince()
			this.getAddress()
		}
		else {
			this.$swal({
				title: 'Oops!', 
				html: '<p>Silakan lakukan Checkout melalui menu Keranjang</p>',
				icon: 'warning',
				timer: 6000,
				showConfirmButton: false,
			});
			this.$router.push('/member/shopping-cart')
		}
	}
}
</script>